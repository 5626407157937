import React from "react";
import "./css/style.css";

const Weatherapp = () => {
    return (
        <>
            <div className="box">

                <div className="inputData">
                    <input
                        type="search"
                        className="inputFeild"
                        onChange={(event) => { }}
                    />
                </div>

                <div className="info">
                    <h2 className="lcoation">
                        <i class="fa-solid fa-street-view">bangalore</i>
                    </h2>

                    <h1 className="temp">

                    </h1>
                    <h3 className="tempmin_max"> min : 5.24cel</h3>
                </div>

                <div className="wave-one"></div>
                <div className="wave-two"></div>
                <div className="wave-three"></div>

            </div>
        </>
    )
}

export default Weatherapp;