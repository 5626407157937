import Weatherapp from './components/tempapp';
import './App.css';
import React from 'react';

function App() {
  return (
    <div>
      <Weatherapp />
    </div>
  );
}

export default App;
